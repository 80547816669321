import React from "react";
import { Helmet } from "react-helmet";
import { Container, Header, Image, Button } from "semantic-ui-react";
import { MainContents } from "../../components/MainContents";
import PageLayout from "../../components/PageLayout";
import { SiteData } from "../../consts/SiteData";
import { URIs } from "../../consts/URIs";

// a
const CubifoxPage = () => {
  return (
    <PageLayout>
      <Helmet>
        <html lang="ja" prefix="og: https://ogp.me/ns#" />
        <title>{SiteData.PAGES.CUBIFOX.TITLE}</title>
        <meta
          property="og:url"
          content="https://www.clsl.net/products/cubifox"
        />
        <meta property="og:type" content="og:product" />
        <meta property="og:title" content="CUBIFOX" />
        <meta
          property="og:description"
          content="3Dオブジェクト×NFTマーケットプレイス CUBIFOX (αテスト版)"
        />
        <meta property="og:site_name" content="Clarity Solutions" />
        <meta
          name="description"
          content="3Dオブジェクト×NFTマーケットプレイス CUBIFOX (αテスト版)"
        />
      </Helmet>
      <MainContents pageTitle="3Dオブジェクト × NFTマーケットプレイス「CUBIFOX (αテスト版)」">
        <p>
          「CUBIFOX」は3Dオブジェクト専門のNFTマーケットプレイスです。
          イーサリアムのサイドチェーンであるPolygonネットワークを利用しているため取引手数料（ガス代）を低く抑えることができるのが特徴です。
          また、データの中央集権化を避けるために3Dオブジェクトデータや3Dオブジェクトのサムネイル画像は分散型ストレージであるIPFS上に保存されています。
          現在はOBJ形式の3Dオブジェクトにのみ対応しておりますが今後のアップデートで他の3Dファイル形式にも対応していく予定です。
          また、
          <a href={URIs.DDDOG_APP} target="_blank" rel="noreferrer">
            「DDDOG」
          </a>
          アプリを使うことで3Dオブジェクトを簡単に生成することができるため、誰もが自作の3DオブジェクトのNFTを作成・販売することが可能になります。
        </p>

        <b>
          （現在はαテスト版として稼働中のため、PolygonのテストネットであるMumbaiを利用したサービス提供となります）
        </b>
        <Container style={{ padding: "5em 0", "text-align": "center" }}>
          <Button
            basic
            as="a"
            href={URIs.CUBIFOX}
            color="blue"
            target="_blank"
            rel="noreferrer"
          >
            <Header as="b" color="blue" textAlign="center">
              CUBIFOX (αテスト版) を使ってみる
            </Header>
          </Button>
        </Container>

        <Header as="h2" content="アイテム一覧画面イメージ" />
        <Container style={{ padding: "30px" }}>
          <Image
            src={"/assets/cubifox_home_image.png"}
            style={{ opacity: 0.8 }}
            fluid
            centered
          />
        </Container>
        <Header as="h2" content="アイテム詳細画面イメージ" />
        <Container style={{ padding: "30px" }}>
          <Image
            src={"/assets/cubifox_trade_item_detail_image.png"}
            style={{ opacity: 0.8 }}
            fluid
            centered
          />
        </Container>
      </MainContents>
    </PageLayout>
  );
};

export default CubifoxPage;
